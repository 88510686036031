import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  requestUnthldDetailProfile,
  receiveUnthldDetailProfile,
  failUntHldDetailProfile,
  requestUntHldDetailDocuments,
  receiveUntHldDetailDocuments,
  failUntHldDetailDocuments,
  requestUntHldDetailAccountLockedStatus,
  receiveUntHldDetailAccountLockedStatus,
  failUntHldDetailAccountLockedStatus,
  requestUntHldDetailDocumentApprove,
  receiveUntHldDetailDocumentApprove,
  failUntHldDetailDocumentApprove,
  requestUntHldDetailDocumentReject,
  receiveUntHldDetailDocumentReject,
  failUntHldDetailDocumentReject,
  requestUntHldDetailDocumentUndo,
  receiveUntHldDetailDocumentUndo,
  failUntHldDetailDocumentUndo,
  requestUntHldDetailApprove,
  receiveUntHldDetailApprove,
  failUntHldDetailApprove,
  requestUntHldDetailReject,
  receiveUntHldDetailReject,
  failUntHldDetailReject,
  requestUntHldDetailCancelStatus,
  receiveUntHldDetailCancelStatus,
  failUntHldDetailCancelStatus,
  requestUntHldDetailFunds,
  receiveUntHldDetailFunds,
  failUntHldDetailFunds,
  requestUntHldDetailAccountUnlock,
  receiveUntHldDetailAccountUnlock,
  failUntHldDetailAccountUnlock,
  receiveCustomerUntHldDetailChannel,
  // -----User Account------------
  REQUsrActSrch,
  RESUsrActSrch,
  FailUsrActSrch,
  // -----UnitHolder------------
  REQUntHldSrch,
  RESUntHldSrch,
  FailUntHldSrch,
  getApplicants,
  UpdateUnitHolderId,
  // regis img
  FETCH_REGISTERED_IMAGE_REQUEST,
  FETCH_REGISTERED_IMAGE_SUCCESS,
  FETCH_REGISTERED_IMAGE_FAILURE,
  // counter service
  FETCH_COUNTERSERVICE_IMAGE_REQUEST,
  FETCH_COUNTERSERVICE_IMAGE_SUCCESS,
  FETCH_COUNTERSERVICE_IMAGE_FAILURE,
  // uploads3
  FETCH_UPLOAD_FILE_TO_S3_SUCCESS,
  FETCH_UPLOAD_FILE_TO_S3_FAILURE,
  FETCH_UPLOAD_FILE_TO_S3_REQUEST,
  // save db
  FETCH_SAVE_TO_DB_FAILURE,
  FETCH_SAVE_TO_DB_REQUEST,
  FETCH_SAVE_TO_DB_SUCCESS

} from "./actions";
import * as actions from "../../Authentication/redux/actions";

import { requestDistricts } from "../../../globalRedux/actions";



function* fetchClientDetail() {
  try {
    const { customerUuid } = yield select(state => state.untHldDetailReducer);
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customers/${customerUuid}?fields=${encodeURIComponent("bank{accountName,accountNo,bankCode,updatedAt,createdAt},children{firstName,birthDate,fullName,identificationNo,lastName,updatedAt,createdAt},contactAddress{address1,address2,countryCode,districtCode,postCode,provinceCode,subDistrict,updatedAt,createdAt},detail{avatar{url},profilePic{url},title,titleOther,birthDate,email,firstName,firstNameTh,lastNameTh,lastName,genderCode,identificationExpireDate,identificationNo,identificationTypeCode,lazerCode,maritalStatusCode,mobile,nationalityCode,taxOption,updatedAt,createdAt},occupation{businessOther,incomeCode,occupationCode,occupationOther,sourceOfFundCode,sourceOfIncomeCode,businessType,totalAssetValue,sourceOfIncomeOther,sourceOfFundOther,position,objectiveInvestmentCode,objectiveInvestmentOther,updatedAt,createdAt},residentialAddress{address1,address2,countryCode,districtCode,postCode,provinceCode,subDistrict,updatedAt,createdAt},risk{level},spouse{firstName,fullName,identificationNo,lastName,updatedAt,createdAt},statusCode,workplaceAddress{address1,address2,countryCode,districtCode,name,postCode,provinceCode,subDistrict,updatedAt,createdAt},verifyChannel,mailingAddress{mailingAddressType,address1,address2,countryCode,districtCode,postCode,provinceCode,subDistrict,updatedAt,createdAt},canApprove")}`,
      {
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (response && response.data) {
      const {
        workplaceAddress,
        residentialAddress,
        contactAddress,
        mailingAddress
      } = response.data.data;
      if (mailingAddress) {
        yield all([
          put(
            requestDistricts({
              provinceCode: mailingAddress.provinceCode,
              type: "districtMailingAddress"
            })
          )
        ]);
      }
      yield all([
        put(
          requestDistricts({
            provinceCode:
              residentialAddress != null
                ? residentialAddress.provinceCode
                : null,
            type: "districtsResidential"
          })
        ),
        put(
          requestDistricts({
            provinceCode:
              workplaceAddress != null ? workplaceAddress.provinceCode : null,
            type: "districtWork"
          })
        ),
        put(
          requestDistricts({
            provinceCode:
              contactAddress != null ? contactAddress.provinceCode : null,
            type: "districtContactAddress"
          })
        )
      ]);

      yield put(receiveCustomerUntHldDetailChannel(response.data.data.verifyChannel));
      yield put(receiveUnthldDetailProfile(response.data.data));
      
    } else {
      yield put(failUntHldDetailProfile());
    }
  } catch (error) {
    console.error("fetchClientDetail error:",error);
    yield put(failUntHldDetailProfile());
  }
}

function* fetchDocumentDetail() {
  try {
    const { customerUuid } = yield select(state => state.untHldDetailReducer);
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customers/${customerUuid}/documents?fields=${encodeURIComponent("lastModifiedAt,statusCode,typeCode,url,uuid")}`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (response && response.data) {
      yield put(receiveUntHldDetailDocuments(response.data.data));
    } else {
      yield put(failUntHldDetailDocuments());
    }
  } catch (error) {
    
    yield put(failUntHldDetailDocuments());
  }
}

function* fetchClientFunds() {
  try {
    const { unitholderId } = yield select(state => state.untHldDetailReducer);
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/portfolio?fields=unitholderId%2CfundNo%2CfundCode%2CnavDate%2CnavPrice%2CcostInvest%2CaverageCost%2Camount%2Cunit%2CprofitLoss%2CprofitPercent%2CfirstSubscription&fundNo=0&unitholderId=${unitholderId}`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (response && response.data && response.data.data) {
      yield put(receiveUntHldDetailFunds(response.data.data));
    } else {
      yield put(failUntHldDetailFunds());
    }
  } catch (error) {
    
    yield put(failUntHldDetailFunds());
  }
}

function* fetchIsUserAccountLockedStatusSaga() {
  try {
  // Get the user's account locked status. If they've entered their credentials wrong three times, then this will tell us that it's locked
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  const untHldDetailReducer= yield select(state => state.untHldDetailReducer)  ;
  const { UserName , email} = yield select(state => state.untHldDetailReducer)  ;
  const url = `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customerAccountStatus/${UserName}`;
    const response = yield call(
      api.get,
      url,
      {
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    var userLockStatus;
    if (response && response.data) {
      if (response.data === "LOCKED_OUT") {
        userLockStatus = "LOCKED";
      } else if (response.data === "ACTIVE") {
        userLockStatus = "UNLOCKED";
      } else if (response.data === "User not found") {
        userLockStatus = "User not found";
      }
    } else if (response.data === "User not found") {
      userLockStatus = "Okta user E-mail is null";
    }

    if (userLockStatus) {
      yield put(receiveUntHldDetailAccountLockedStatus(userLockStatus));
    } else {
      yield put(failUntHldDetailAccountLockedStatus());
    }
  } catch (error) {
    console.error("fetchIsUserAccountLockedStatusSaga:",error);
    yield put(failUntHldDetailAccountLockedStatus(error));
  }
}

function* fetchUserAccountUnlock(action) {
  try {
    const { payload } = action;
    const accessToken = yield select(state => state.auth.accessToken);
    const userDetail = yield select(state => state.untHldDetailReducer);
    const tGUID = yield select(state => state.auth.GUID);

    var clientUserId = "";
    if (payload) {
      clientUserId = payload.customerId;
    } else {
      try {
        clientUserId = userDetail.clientDetail.detail.email;
      } catch (e) {
        console.log('fetchUserAccountUnlock catch', e);
      }
    }

    const body = {};
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/unlockUser/${clientUserId}`,
      body,
      {
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (response && (response.status === 204 || response.status === 200)) {
      yield put(getApplicants({ offset: 0, limit: 10 }));
      yield put(receiveUntHldDetailAccountUnlock());
    } else {
      yield put(failUntHldDetailAccountUnlock());
    }
  } catch (error) {
    
    yield put(failUntHldDetailAccountUnlock(error));
  }
}

function* fetchDocumentApproval({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { documentID, avatar } = payload;

    const body = {
      statusCode: "2"
    };

    const response = yield call(
      api.patch,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/documents/${documentID}/status?fields=lastModified`,  
      body,
      {
        headers: {
          "Content-Type": `application/json`,
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.lastModified
    ) {
      yield put(receiveUntHldDetailDocumentApprove());
      yield put(requestUntHldDetailDocuments(avatar));
    } else {
      yield put(failUntHldDetailDocumentApprove());
    }
  } catch (error) {
    
    yield put(failUntHldDetailDocumentApprove());
  }
}

function* fetchDocumentRejection({ payload }) {
  try {
    /*
      Updates the document's status to Rejected.
    */
    const { documentID, avatar } = payload;

    const body = {
      statusCode: "3"
    };
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);

    const response = yield call(
      api.patch,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/documents/${documentID}/status?fields=lastModified`,
      body,
      {
        headers: {
          "Content-Type": `application/json`,
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.lastModified
    ) {
      yield put(receiveUntHldDetailDocumentReject());
      yield put(requestUntHldDetailDocuments(avatar));
    } else {
      yield put(failUntHldDetailDocumentReject());
    }
  } catch (error) {
    
    yield put(failUntHldDetailDocumentReject());
  }
}

function* fetchDocumentUndo({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    /*
      Updates the document's status to Pending (essentially undoing an Approve or Rejection).
    */
    const { documentID, avatar } = payload;

    const body = {
      statusCode: "1"
    };

    const response = yield call(
      api.patch,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/documents/${documentID}/status?fields=lastModified`,
      body,
      {
        headers: {
          "Content-Type": `application/json`,
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.lastModified
    ) {
      yield put(receiveUntHldDetailDocumentUndo());
      yield put(requestUntHldDetailDocuments(avatar));
    } else {
      yield put(failUntHldDetailDocumentUndo());
    }
  } catch (error) {
    
    yield put(failUntHldDetailDocumentUndo());
  }
}

// start test
function generateRequestBody(clientDetail) {
  return JSON.stringify({
    customer_detail: clientDetail.detail.updatedAt || null,
    residential_address: clientDetail.residentialAddress.updatedAt || null,
    customer_bank: clientDetail.bank.updatedAt || null,
    contact_address: clientDetail.contactAddress.updatedAt || null,
    occupation_detail: clientDetail.occupation.updatedAt || null,
    workplace_address: clientDetail.workplaceAddress.updatedAt || null,
    mailing_address: clientDetail.mailingAddress.updatedAt || null
  });
}

function* handleClientApproveResponseError(error) {
  console.log("handleClientApproveResponseError>>",error);
  if (error.response.error.message.message.errorCode === "EPV") {
    yield put(failUntHldDetailApprove({ header: error.response.error.message.message.errorDetails[0].errorDesc, data: [] }));
  } else if (error.response.error.message.message.errorCode === "EID") {
    yield put(failUntHldDetailApprove({ header: error.response.error.message.message.message, data: [] }));
  } else {
    yield put(failUntHldDetailApprove({ header: "เกิดข้อผิดพลาด (api error)", data: [] }));
  }
}

function* fetchClientApprove() {
  try {
    console.log("fetchClientApprove start");
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { customerUuid } = yield select(state => state.untHldDetailReducer);
    const body = {
      userId: customerUuid
    };
    console.log("fetchClientApprove body:",JSON.stringify(body));
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/approve-user`,
      body,
      {
        headers: {
           userid: tGUID,
           id_token: "Bearer " + accessToken
         }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    
    if (response && response.data?.data?.status === "success") {
      console.log("requestUnthldDetailProfile ::");
      yield put(requestUnthldDetailProfile());
      console.log("receiveUntHldDetailApprove ::");
      yield put(receiveUntHldDetailApprove());
      console.log("REQUntHldSrch ::");
      yield put(REQUntHldSrch({
        page: 1,
        tSrch: customerUuid,
        tOpSrch: "UUID",
        FltDocSta: "2",
        FltAccSta: ["2"],
        FltConvertUsr: "'',1"
      }));
      console.log("Set unitholderId ::");
      yield put(UpdateUnitHolderId(response.data.data.unitholderId));
    } else {
      if(response?.data.error?.errorDetails){
        yield put(failUntHldDetailApprove({ header: response?.data.error?.errorDetails[0]?.errorDesc || response.data.error?.name, data: [] }));
      }else{
        yield put(failUntHldDetailApprove({ header: response.data.error?.name, data: [] }));
      }

    }
  } catch (error) {
    console.error("catch fetchClientApprove error:",error);
    yield handleClientApproveError(error);
  }
}

function* handleClientApproveError(error) {
  console.log("handleClientApproveError error:",error);
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  const { customerUuid, clientDetail } = yield select(state => state.untHldDetailReducer);
  const body = yield generateRequestBody(clientDetail);
  const response = yield call(
    api.put,
    `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/compareDataTimestamp/${customerUuid}`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
      }
    }
  );
  if (response.status === 401) {
    yield put(actions.autoSignOut()); 
    return; 
  }
  if (response.data.data.length > 0) {
    yield put(failUntHldDetailApprove({ header: "The information is updated, the system will refresh itself. Please check the information once again.", data: response.data.data }));
  } else {
    yield handleClientApproveResponseError(error);
  }
}
// end test

function* fetchClientReject() {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { customerUuid } = yield select(state => state.untHldDetailReducer);
    const body = {
      userId: customerUuid
    };
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/reject-user`,
      body,
      { 
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    if (response && response.data) {
      yield put(requestUnthldDetailProfile());
      yield put(receiveUntHldDetailReject());
    } else {
      yield put(failUntHldDetailReject());
    }
  } catch (error) {
    
    yield put(failUntHldDetailReject());
  }
}

function* fetchCancelStatus() {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { customerUuid } = yield select(state => state.untHldDetailReducer);
    const body = {
      userId: customerUuid
    };

    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/cancel-user`,
      body,
      { 
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    if (response && response.data && response.data.data) {
      yield put(requestUnthldDetailProfile());
      yield put(receiveUntHldDetailCancelStatus());
    } else {
      yield put(failUntHldDetailCancelStatus());
    }
  } catch (error) {
    
    yield put(failUntHldDetailCancelStatus());
  }
}


// start test
function buildSearchParams(tSrch, tOpSrch) {
  const params = {
    tEmailSrch: "",
    tUsrNameSrch: "",
    tUUIDSrch: "",
    tNameENSrch: "",
    tNameTHSrch: "",
    tIDNoSrch: "",
    tMobileSrch: ""
  };

  switch (tOpSrch) {
    case "Email":
      params.tEmailSrch = tSrch;
      break;
    case "Username":
      params.tUsrNameSrch = tSrch;
      break;
    case "UUID":
      params.tUUIDSrch = tSrch;
      break;
    case "English Name":
      params.tNameENSrch = tSrch;
      break;
    case "Thai Name":
      params.tNameTHSrch = tSrch;
      break;
    case "ID No. / Passport No.":
      params.tIDNoSrch = tSrch;
      break;
    case "Mobile":
      params.tMobileSrch = tSrch;
      break;
    default: 
      break; 
  }

  return params;
}


function formatAccountStages(tFltActStage) {
  const filteredStages = tFltActStage.filter(f => f !== "Verified by");
  const formattedStages = filteredStages.map(m => `'${m}'`).join(",");

  // Ensure at least one element in formattedStages
  let tActStg = formattedStages === "" ? "''" : `${formattedStages},''`;
  let tActStgVerify = tFltActStage.includes("Verified by") ? "Verified by" : "";

  return { tActStg, tActStgVerify };
}

function* fetchUsrActSrchWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { page, tSrch, tOpSrch, tSortH, tFltActStage } = payload;

    const searchParams = buildSearchParams(tSrch, tOpSrch);
    const { tActStg, tActStgVerify } = formatAccountStages(tFltActStage);
    
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/userAccount?page=${page}`,
      {
        method: "POST",  
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          ...searchParams,
          sortH: tSortH,
          FltAccountStage: tActStg,
          FltAccountStageByVerify: tActStgVerify,
          FltAccountType:
            "'B2C','EasyInvest','Has customer_detail','EasyInvest has customer_detail','Invalid'"
        })
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    
    const response = yield request.json();
    
    if (response) {
      yield put(RESUsrActSrch(response));
    } else {
      yield put(RESUsrActSrch());
    }
  } catch (error) {
    yield put(FailUsrActSrch());
  }
}
// end test

// start test

function formatFilterValues(FltDocSta, FltAccSta, FltConvertUsr) {
  let formattedDocSta = FltDocSta === ",1" ? "1,''" : FltDocSta === ",2" ? "2,''" : FltDocSta === ",3" ? "3,''": "'',1,2,3";
  let formattedAccSta = FltAccSta.filter(f => f !== "ALL").join(",") || "''";
  let formattedConvertUsr = FltConvertUsr === "" ? "'',1" : FltConvertUsr.replace(/,$/, "");

  return { formattedDocSta, formattedAccSta, formattedConvertUsr };
}

function* fetchUntHldSrchWorker({ payload }) {
  try {
    const {
      customerUuid
    } = yield select(state => state.untHldDetailReducer);

    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const searchParams = buildSearchParams(tSrch, tOpSrch);
    const { formattedDocSta, formattedAccSta, formattedConvertUsr } = formatFilterValues(FltDocSta, FltAccSta, FltConvertUsr);

    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/unitHolder?page=${page}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          ...searchParams,
          FltStaCode: formattedAccSta,
          FltDocStaCode: formattedDocSta,
          FltConVertUsr: formattedConvertUsr
        })
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    const response = yield request.json();
    
    if (response) {
      let aMemPageUntHldSrch = {
        uuid: customerUuid,
        unitholderid: "fetchUntHldSrchWorker",
        UsrAccountType: yield select(state => state.untHldDetailReducer.UsrAccountType),
        NameTH: yield select(state => state.untHldDetailReducer.NameTH),
        UserName: yield select(state => state.untHldDetailReducer.UserName),
        PageCurrent: yield select(state => state.untHldDetailReducer.PageCurrent),
        UISearch: yield select(state => state.untHldDetailReducer.UISearch)
      };

      yield put(
        RESUntHldSrch({
          response: response,
          aMemPageUntHldSrch: aMemPageUntHldSrch
        })
      );
    } else {
      yield put(RESUntHldSrch());
    }
  } catch (error) {
    yield put(FailUntHldSrch());
  }
}

function* fetchRegisteredImageWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { customerUuid } = yield select(state => state.untHldDetailReducer);
    const body = {
      customerUuid: customerUuid
    };
    const request = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/refreshNdidPhoto`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
            id_token: "Bearer " + accessToken,
        }
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = request.data;

    if (response) {
      yield put(FETCH_REGISTERED_IMAGE_SUCCESS(response));
    } else {
      yield put(FETCH_REGISTERED_IMAGE_FAILURE());
    }
  } catch (error) {
    yield put(FETCH_REGISTERED_IMAGE_FAILURE(error.message));
  }
}

function* fetchCounterServiceImageWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { customerUuid } = yield select(state => state.untHldDetailReducer);
    const body = {
      customerUuid: customerUuid
    };
    const request = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/refreshCounterServicePhoto`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
            id_token: "Bearer " + accessToken,
        }
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = request.data;

    if (response) {
      yield put(FETCH_COUNTERSERVICE_IMAGE_SUCCESS(response));
    } else {
      yield put(FETCH_COUNTERSERVICE_IMAGE_FAILURE());
    }
  } catch (error) {
    yield put(FETCH_COUNTERSERVICE_IMAGE_FAILURE(error.message));
  }
}

function* fetchUploadFileToS3Worker( payload ) {
  try {
    
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { customerUuid } = yield select(state => state.untHldDetailReducer);
    const  position  = payload.payload;
    const body = {
      customerUuid: customerUuid,
      position: position
    };
        // Fetch the pre-signed URL
    const presignedrequest = yield call(
      api.put,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/me/identificationPresignedPostPolicy?fields=${encodeURIComponent('formDatas,postUrl')}`,
      body,
      {
      headers: {
        'Content-Type': 'application/json',
        'content-language': 'th',
        userid: tGUID,
        id_token: 'Bearer ' + accessToken,

      },

      },
    );
    if (presignedrequest.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = presignedrequest.data
    
    if (response) {
      yield put(FETCH_UPLOAD_FILE_TO_S3_SUCCESS(response));
    } else {
      yield put(FETCH_UPLOAD_FILE_TO_S3_FAILURE());
    }
  } catch (error) {
    yield put(FETCH_UPLOAD_FILE_TO_S3_FAILURE(error.message));
  }
}

function* fetchSaveS3ToDatabaseWorker( payload ) {
  console.log("fetch save to db called")
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { customerUuid } = yield select(state => state.untHldDetailReducer);
    const  position  = payload.payload;
    const body = {
      customerUuid: customerUuid,
      position: position
    };
    // Fetch the pre-signed URL
    const presignedrequest = yield call(
      api.put,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/me/identificationUploadStat?fields=${encodeURIComponent('lastModified,url,urlExpire')}`,
      body,
      {
      headers: {
        'Content-Type': 'application/json',
        'content-language': 'th',
        userid: tGUID,
        id_token: 'Bearer ' + accessToken,

      },

      },
    );
    if (presignedrequest.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = presignedrequest.data
    
    if (response) {
      yield put(FETCH_SAVE_TO_DB_SUCCESS(response));
    } else {
      yield put(FETCH_SAVE_TO_DB_FAILURE());
    }
  } catch (error) {
    yield put(FETCH_SAVE_TO_DB_FAILURE(error.message));
  }
}
// end test
export function* untHldDetailSaga() {
  yield all([
    takeLatest(requestUnthldDetailProfile, fetchClientDetail),
    takeLatest(requestUntHldDetailDocuments, fetchDocumentDetail),
    takeLatest(
      requestUntHldDetailAccountLockedStatus,
      fetchIsUserAccountLockedStatusSaga
    ),
    takeLatest(requestUntHldDetailAccountUnlock, fetchUserAccountUnlock),
    takeLatest(requestUntHldDetailDocumentApprove, fetchDocumentApproval),
    takeLatest(requestUntHldDetailDocumentReject, fetchDocumentRejection),
    takeLatest(requestUntHldDetailDocumentUndo, fetchDocumentUndo),
    takeLatest(requestUntHldDetailApprove, fetchClientApprove),
    takeLatest(requestUntHldDetailReject, fetchClientReject),
    takeLatest(requestUntHldDetailCancelStatus, fetchCancelStatus),
    takeLatest(requestUntHldDetailFunds, fetchClientFunds),

    takeLatest(REQUsrActSrch, fetchUsrActSrchWorker),
    takeLatest(REQUntHldSrch, fetchUntHldSrchWorker),
    takeLatest(FETCH_REGISTERED_IMAGE_REQUEST, fetchRegisteredImageWorker),
    takeLatest(FETCH_COUNTERSERVICE_IMAGE_REQUEST, fetchCounterServiceImageWorker),
    takeLatest(FETCH_UPLOAD_FILE_TO_S3_REQUEST, fetchUploadFileToS3Worker),
    takeLatest(FETCH_SAVE_TO_DB_REQUEST, fetchSaveS3ToDatabaseWorker)
  ]);
}
