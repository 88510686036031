import React from "react";
import { Link, Route } from "react-router-dom";
import "./style.scss";

const routes = {
  "/": "CLIENTS  LIST",
  NDID: "NDID",
  CouterService: "COUTER SERVICE",
  UsrAct: "USER ACCOUNT",

  Tag: "TAG",
  SearchRec: "SEARCH RECOMMEND",

  FundRec_FundMan: "FUND RECOMMEND",
  FundRec_Condi: "FUND RECOMMEND",
  FundRec_FundRisk: "FUND RECOMMEND",

  fund_list: "FUND LIST",

  transaction: "TRANSACTION",
  transaction_details: "TRANSACTION",
  rsp_account_details: "TRANSACTION",
  request_ats_details: "TRANSACTION",

  ConFd: "CONTENT FEED -> อื่นๆ",
  ConFd_NewsOld: "CONTENT FEED -> ข่าวสาร",
  ConFd_Invest: "CONTENT FEED -> มุมมองการลงทุน",
  ConFd_News: "CONTENT FEED -> ข่าวสารและรางวัล",
  ConFd_ContentInvest: "CONTENT FEED -> สาระการลงทุน",
  ConFd_ManualInvest: "CONTENT FEED -> คู่มือนักลงทุน",
  ConFd_NotifyInvest: "CONTENT FEED -> ประกาศกองทุนรวม",
  ConFd_Others: "CONTENT FEED -> อื่นๆ",

  ContenetFeedSort: "CONTENT FEED SORT",
  RegisDevice: "REGISTERED DEVICE",

  Activity: "ACTIVITY LOG",
  Activity_Usr: "ACTIVITY LOG",
  Activity_Admin: "ACTIVITY LOG",

  RegisLog: "REGISTER LOG",
  RegisLog_CounterService: "REGISTER LOG",
  RegisLog_NDID: "REGISTER LOG",
  RegisLogDetail: "REGISTER LOG",

  Analytic: "ANALYTIC",
  EasyInvest: "ANALYTIC",
  Applicant: "ANALYTIC"
};

const returnRouteName = location => {
  return routes[location];
};
const GENxLink = (
  tPathRoute,
  aMemPageAll,
  pnUsrAct_PageCurrent,
  pnUntHld_PageCurrent,
  pnRegDevice_PageCurrent,
  pnFundsList_PageCurrent,
  pnAnalytic_PageCurrent
) => {
  
  let tPath1 = tPathRoute.split("/");
  tPath1 = tPath1[1];

  
  switch (tPath1) {
    // Page Detail มักจะอยู่ตอนกด Detail
    case "applicants":
      return (
        <div>
          <Link to="#" className="first-link">
            CLIENTS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">APPLICANTS</span>
        </div>
      );
    case "UnitHolder":
      return (
        <div>
          <Link to="#" className="first-link">
            CLIENTS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">UNITHOLDER</span>
        </div>
      );
    case "UnitHolderDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/UnitHolder",
              PageCurrent: pnUntHld_PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
          >
            CLIENTS
          </Link>

          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/UnitHolder",
              PageCurrent: pnUntHld_PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
          >
            UNITHOLDER
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">
            {aMemPageAll.unitholderid === "" ? "N/A" : aMemPageAll.unitholderid}
          </span>
        </div>
      );
    case "UsrAct":
      if (aMemPageAll === undefined) {
        return (
          <div>
            <Link className="first-link" to="/UsrAct">
              CLIENTS
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">USER ACCOUNT</span>
          </div>
        );
      } else {
        return (
          <div>
            <Link
              className="first-link"
              to={{
                pathname: "/UsrAct",
                PageCurrent: pnUsrAct_PageCurrent,
                UISearch: aMemPageAll.UISearch
              }}
            >
              CLIENTS
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">USER ACCOUNT</span>
          </div>
        );
      }
    case "UsrActDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/UsrAct",
              PageCurrent: pnUsrAct_PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
          >
            CLIENTS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/UsrAct",
              PageCurrent: pnUsrAct_PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
          >
            USER ACCOUNT
          </Link>

          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">
            {
              aMemPageAll.RowDataAt===undefined
              // from UnitHolderTab1Detail
              ? aMemPageAll.username
              : aMemPageAll.RowDataAt.username
            }
          </span>
        </div>
      );
    case "UnitHolderTab1Detail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/UsrAct",
              PageCurrent: pnUsrAct_PageCurrent,
              UISearch:aMemPageAll.UISearch
            }}
          >
            CLIENTS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/UsrAct",
              PageCurrent: pnUsrAct_PageCurrent,
              UISearch:aMemPageAll.UISearch
            }}
          >
            USER ACCOUNT
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: `/UsrActDetail/${aMemPageAll.uuid}`,
              RowDataAt: aMemPageAll.RowDataAt
            }}
          >
             {aMemPageAll.UserName}
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to={`/UsrActDetail/${aMemPageAll.uuid}`} className="first-link">
            UNITHOLDER
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">
            {aMemPageAll.unitholderid === null
              ? "N/A"
              : aMemPageAll.unitholderid}
          </span>
        </div>
      );
    case "RegisTab2DeviceDetail":
      return (
        <div>
          <Link to="/UsrAct" className="first-link">
            CLIENTS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to="/UsrAct" className="first-link">
            USER ACCOUNT
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            to={`/UsrActDetail/${aMemPageAll.uuid}`}
            className="first-link"
          >
            {aMemPageAll.UserName}
          </Link>

          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">REGISTER DEVICE</span>
        </div>
      );
    case "UsrActDetail_ViewLog":
      return (
        <div>
          <Link to="/UsrAct" className="first-link">
            CLIENTS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to="/UsrAct" className="first-link">
            USER ACCOUNT
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            to={`/UsrActDetail/${aMemPageAll.RowDataAt.id}`}
            className="first-link"
          >
            {aMemPageAll.RowDataAt.username}
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">ACTIVITY LOG</span>
        </div>
      );
    case "UsrActDetail_ViewLogDetail":
      return (
        <div>
          <Link to="/UsrAct" className="first-link">
            CLIENTS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to="/UsrAct" className="first-link">
            USER ACCOUNT
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to={`/UsrActDetail/${aMemPageAll.id}`} className="first-link">
            {aMemPageAll.username}
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/UsrActDetail_ViewLog",
              search: "?srch=" + aMemPageAll.email
            }}
          >
            ACTIVITY LOG
          </Link>

          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">ACTIVITY LOG DETAIL</span>
        </div>
      );
    case "ImportRGTtoB2C":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/ImportRGTtoB2C"
            }}
          >
            CLIENTS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">IMPORT RGT</span>
        </div>
      );
    case "TagDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/Tag",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            TAG
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "TagCrt":
      return (
        <div>
          <Link to="/Tag" className="first-link">
            TAG
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">CREATE NEW TAG</span>
        </div>
      );
    case "SrchRecDetail":
      return (
        <div>
          <Link to="/SearchRec" className="first-link">
            SEARCH RECOMMEND
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "FundRec_FundMan":
      return (
        <div>
          <Link to="#" className="first-link">
            FUND RECOMMEND
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">BY FUND MANAGER</span>
        </div>
      );
    case "FundRec_FundManView":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/FundRec_FundMan",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            FUND RECOMMEND
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/FundRec_FundMan",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            BY FUND MANAGER
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">
            {
            aMemPageAll.mode === "CREATE" ? "": aMemPageAll?.detailsFromList?.fund_no
            }
          </span>
        </div>
        
      );
    case "FundRec_FundManEdit":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/FundRec_FundMan",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            FUND RECOMMEND
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/FundRec_FundMan",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            BY FUND MANAGER
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "FundRec_FundManCrt":
      return (
        <div>
          <Link to="/FundRec_FundMan" className="first-link">
            FUND RECOMMEND
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to="/FundRec_FundMan" className="first-link">
            BY FUND MANAGER
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">Create</span>
        </div>
      );
    case "FundRec_Condi":
      return (
        <div>
          <Link to="#" className="first-link">
            FUND RECOMMEND
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">BY CONDITION</span>
        </div>
      );
    case "FundRec_CodiDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/FundRec_Condi",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            FUND RECOMMEND
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/FundRec_Condi",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            BY CONDITION
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "FundRec_CodiCrt":
      return (
        <div>
          <Link to="/FundRec_Condi" className="first-link">
            FUND RECOMMEND
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to="/FundRec_Condi" className="first-link">
            BY CONDITION
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">CREATE NEW CONDITION</span>
        </div>
      );
    case "FundRec_FundRisk":
      return (
        <div>
          <Link to="#" className="first-link">
            FUND RECOMMEND
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">SIMPLE ADVISORY</span>
        </div>
      );
    case "FundRec_RiskDetail":
      return (
        <div>
          <Link to="/FundRec_FundRisk" className="first-link">
            FUND RECOMMEND
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to="/FundRec_FundRisk" className="first-link">
            SIMPLE ADVISORY
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link"> {aMemPageAll.portnameen}</span>
        </div>
      );
    case "ConFd":
      return (
        <div>
          <Link to="#" className="first-link">
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">อื่นๆ</span>
        </div>
      );
    case "ConFd_NewsOld":
      return (
        <div>
          <Link to="#" className="first-link">
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">ข่าวสาร</span>
        </div>
      );
    case "ConFd_NewsOldDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_NewsOld",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_NewsOld",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            ข่าวสาร
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "ConFd_Invest":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_NewsOld"
            }}
          >
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">มุมมองการลงทุน</span>
        </div>
      );
    case "ConFd_InvestDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_Invest",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_Invest",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            มุมมองการลงทุน
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "ConFd_News":
      return (
        <div>
          <Link to="#" className="first-link">
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">ข่าวสารและรางวัล</span>
        </div>
      );
    case "ConFd_NewsDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_News",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_News",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            ข่าวสารและรางวัล
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "ConFd_ContentInvest":
      return (
        <div>
          <Link to="#" className="first-link">
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">สาระการลงทุน</span>
        </div>
      );
    case "ConFd_ContentInvestDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_ContentInvest",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_ContentInvest",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            สาระการลงทุน
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "ConFd_ManualInvest":
      return (
        <div>
          <Link to="#" className="first-link">
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">คู่มือนักลงทุน</span>
        </div>
      );
    case "ConFd_ManualInvestDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_ManualInvest",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_ManualInvest",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            คู่มือนักลงทุน
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "ConFd_NotifyInvest":
      return (
        <div>
          <Link to="#" className="first-link">
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">ประกาศกองทุนรวม</span>
        </div>
      );
    case "ConFd_NotifyInvestDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_NotifyInvest",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_NotifyInvest",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            ประกาศกองทุนรวม
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "ConFd_Srch":
      return (
        <div>
          <Link to="#" className="first-link">
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">ค้นหา</span>
        </div>
      );
    case "ConFd_SrchFdDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_Srch",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link
            className="first-link"
            to={{
              pathname: "/ConFd_Srch",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            ค้นหา
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "ConFd_SrchNotiDetail":
      return (
        <div>
          <Link to="/ConFd_Srch" className="first-link">
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to="/ConFd_Srch" className="first-link">
            ค้นหา
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "ConFdCrt":
      return (
        <div>
          <Link to="/ConFd_NewsOld" className="first-link">
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">CREATE NEW CONTENT FEED</span>
        </div>
      );
    case "ConFdNotiInvestCrt":
      return (
        <div>
          <Link to="/ConFdNotiInvestCrt" className="first-link">
            CONTENT FEED
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">CREATE NEW CONTENT FEED</span>
        </div>
      );
    case "RegisDeviceDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/RegisDevice",
              PageCurrent: pnRegDevice_PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
          >
            REGISTERED DEVICE
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">REGISTERED DEVICE DETAIL</span>
        </div>
      );
      case "ConFd_PVDNewsReport":
        return (
          <div>
            <Link to="#" className="first-link">
              CONTENT FEED
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">รายงาน ข่าวสารกองทุนสำรองเลี้ยงชีพ</span>
          </div>
        );
      case "ConFd_PVDNewsReportDetail":
        return (
          <div>
            <Link
              className="first-link"
              to={{
                pathname: "/ConFd_PVDNewsReport",
                PageCurrent: aMemPageAll.PageCurrent
              }}
            >
              CONTENT FEED
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <Link
              className="first-link"
              to={{
                pathname: "/ConFd_PVDNewsReport",
                PageCurrent: aMemPageAll.PageCurrent
              }}
            >
              รายงาน ข่าวสารกองทุนสำรองเลี้ยงชีพ
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">EDIT</span>
          </div>
        );
        case "ConFd_PVDPlaning":
          return (
            <div>
              <Link to="#" className="first-link">
                CONTENT FEED
              </Link>
              <span className="icon-right">
                <i className="fas fa-chevron-right" />
              </span>
              <span className="non-active-link">คลังความรู้ด้านการวางแผนการเงิน</span>
            </div>
          );
        case "ConFd_PVDPlaningDetail":
          return (
            <div>
              <Link
                className="first-link"
                to={{
                  pathname: "/ConFd_PVDPlaning",
                  PageCurrent: aMemPageAll.PageCurrent
                }}
              >
                CONTENT FEED
              </Link>
              <span className="icon-right">
                <i className="fas fa-chevron-right" />
              </span>
              <Link
                className="first-link"
                to={{
                  pathname: "/ConFd_PVDPlaning",
                  PageCurrent: aMemPageAll.PageCurrent
                }}
              >
                คลังความรู้ด้านการวางแผนการเงิน
              </Link>
              <span className="icon-right">
                <i className="fas fa-chevron-right" />
              </span>
              <span className="non-active-link">EDIT</span>
            </div>
          );
          case "ConFd_PVDFactSheet":
            return (
              <div>
                <Link to="#" className="first-link">
                  CONTENT FEED
                </Link>
                <span className="icon-right">
                  <i className="fas fa-chevron-right" />
                </span>
                <span className="non-active-link">หนังสือชี้ชวนส่วนสรุปกองทุนสำรองเลี้ยงชีพ</span>
              </div>
            );
          case "ConFd_PVDFactSheetDetail":
            return (
              <div>
                <Link
                  className="first-link"
                  to={{
                    pathname: "/ConFd_PVDFactSheet",
                    PageCurrent: aMemPageAll.PageCurrent
                  }}
                >
                  CONTENT FEED
                </Link>
                <span className="icon-right">
                  <i className="fas fa-chevron-right" />
                </span>
                <Link
                  className="first-link"
                  to={{
                    pathname: "/ConFd_PVDFactSheet",
                    PageCurrent: aMemPageAll.PageCurrent
                  }}
                >
                  หนังสือชี้ชวนส่วนสรุปกองทุนสำรองเลี้ยงชีพ
                </Link>
                <span className="icon-right">
                  <i className="fas fa-chevron-right" />
                </span>
                <span className="non-active-link">EDIT</span>
              </div>
            );
    case "Activity":
    case "Activity_Usr":
      if (aMemPageAll === undefined) {
        
        return (
          <div>
            <Link className="first-link" to="/Activity_Usr">
              ACTIVITY LOG
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">USER</span>
          </div>
        );
      } else {
        return (
          <div>
            <Link
              className="first-link"
              to={{
                pathname: "/Activity_Usr",
                PageCurrent: pnUsrAct_PageCurrent,
                UISearch: aMemPageAll.UISearch
              }}
            >
              ACTIVITY LOG
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">USER</span>
          </div>
        );
      }
    case "Activity_Admin":
      return (
        <div>
          <Link to="/Activity_Admin" className="first-link">
            ACTIVITY LOG
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">ADMIN</span>
        </div>
      );
    case "ActivityDetail_Usr":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/Activity_Usr",
              PageCurrent: aMemPageAll.PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
          >
            ACTIVITY LOG
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">ACTIVITY LOG DETAIL</span>
        </div>
      );
    case "ActivityDetail_Admin":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/Activity_Admin",
              PageCurrent: aMemPageAll.PageCurrent
            }}
          >
            ACTIVITY LOG
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">ACTIVITY LOG DETAIL</span>
        </div>
      );
    case "RegisLog":
    case "RegisLog_CounterService":
      if (aMemPageAll === undefined) {
        return (
          <div>
            <Link to="/RegisLog_CounterService" className="first-link">
              REGISTER LOG
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">COUNTER SERVICE</span>
          </div>
        );
      } else {
        return (
          <div>
            <Link
              className="first-link"
              to={{
                pathname: "/RegisLog_CounterService",
                PageCurrent: aMemPageAll.PageCurrent,
                RegisLogType: aMemPageAll.RegisLogType,
                UISearch: aMemPageAll.UISearch
              }}
            >
              REGISTER LOG
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">COUNTER SERVICE</span>
          </div>
        );
      }
    case "RegisLog_NDID":
      if (aMemPageAll === undefined) {
        return (
          <div>
            <Link className="first-link" to="/RegisLog_NDID">
              REGISTER LOG
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">NDID</span>
          </div>
        );
      } else {
        return (
          <div>
            <Link
              className="first-link"
              to={{
                pathname: "/RegisLog_NDID",
                PageCurrent: aMemPageAll.PageCurrent,
                RegisLogType: aMemPageAll.RegisLogType,
                UISearch: aMemPageAll.UISearch
              }}
            >
              REGISTER LOG
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">NDID</span>
          </div>
        );
      }

    case "RegisLogDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname:
                aMemPageAll.RegisLogType === "counter_service"
                  ? "/RegisLog_CounterService"
                  : "/RegisLog_NDID",
              PageCurrent: aMemPageAll.PageCurrent,
              RegisLogType: aMemPageAll.RegisLogType,
              UISearch: aMemPageAll.UISearch
            }}
          >
            REGISTER LOG
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>

          <Link
            to={{
              pathname:
                aMemPageAll.RegisLogType === "counter_service"
                  ? "/RegisLog_CounterService"
                  : "/RegisLog_NDID",
              PageCurrent: aMemPageAll.PageCurrent,
              RegisLogType: aMemPageAll.RegisLogType,
              UISearch: aMemPageAll.UISearch
            }}
            className="first-link"
          >
            {aMemPageAll.RegisLogType === "counter_service"
              ? "COUNTER SERVICE"
              : "NDID"}
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">{aMemPageAll.RowDataAt.id}</span>
        </div>
      );
    case "NDIDLog":
      if (aMemPageAll === undefined) {
        return (
          <div>
            <Link className="first-link" to="/NDIDLog">
              REGISTER LOG
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">NDID LOG</span>
          </div>
        );
      } else {
        return (
          <div>
            <Link
              className="first-link"
              to={{
                pathname: "/NDIDLog",
                PageCurrent: aMemPageAll.PageCurrent,
                UISearch: aMemPageAll.UISearch
              }}
            >
              REGISTER LOG
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">NDID LOG</span>
          </div>
        );
      }
    case "NDIDLogDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/NDIDLog",
              PageCurrent: aMemPageAll.PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
          >
            REGISTER LOG
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>

          <Link
            to={{
              pathname: "/NDIDLog",
              PageCurrent: aMemPageAll.PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
            className="first-link"
          >
            NDID LOG
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">
            {aMemPageAll.RowDataAt.identification_no === null
              ? "N/A"
              : aMemPageAll.RowDataAt.identification_no}
          </span>
        </div>
      );

    case "Consent":
      return (
        <div>
          <Link to="/Consent" className="first-link">
            CONSENT
          </Link>
        </div>
      );
    case "Version":

      if (aMemPageAll.UISearch.tConsentType === undefined) {
        return (
          <div>
            <Link to="/Consent" className="first-link">
              CONSENT
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">
              {aMemPageAll.RowDataAt.consent_type}
            </span>
          </div>
        );
      } else {
        
        return (
          <div>
            <Link to="/Consent" className="first-link">
              CONSENT
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">
              {aMemPageAll.UISearch.tConsentType}
            </span>
          </div>
        );
      }
    case "VersionDetail":
      return (
        <div>
          <Link to="/Consent" className="first-link">
            CONSENT
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link" />
          <Link
            to={{
              pathname: "/Version",
              PageCurrent: aMemPageAll.PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
            className="first-link"
          >
            {aMemPageAll.UISearch.tConsentType}
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">
            VERSION {aMemPageAll.RowDataAt.consent_version}
          </span>
        </div>
      );
    case "Analytic":
    case "Analytic_EsyInv":
      return (
        <div>
          <Link to="/Analytic" className="first-link">
            ANALYTIC
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">E@SYINVEST</span>
        </div>
      );
    case "AnalyticDetail":
      if (aMemPageAll === undefined) {
        return (
          <div>
            <Link to="/Analytic" className="first-link">
              ANALYTIC
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">E@SYInvest</span>
          </div>
        );
      } else {
        return (
          <div>
            <Link
              className="first-link"
              to={{
                pathname: "/Analytic",
                PageCurrent: pnAnalytic_PageCurrent,
                UISearch: aMemPageAll.UISearch
              }}
            >
              ANALYTIC
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <Link
              className="first-link"
              to={{
                pathname: "/Analytic",
                PageCurrent: pnAnalytic_PageCurrent,
                UISearch: aMemPageAll.UISearch
              }}
            >
              E@SYInvest
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">
              {aMemPageAll.EasyInvestConversionType} ={" "}
              {aMemPageAll.TotalEasyInvestConversion} record
            </span>
          </div>
        );
      }
    case "Analytic_Apps":
      return (
        <div>
          <Link to="/Analytic_Apps" className="first-link">
            ANALYTIC
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">APPLICANT</span>
        </div>
      );
    case "AnalyticAppsDetail":
      if (aMemPageAll === undefined) {
        return (
          <div>
            <Link to="/Analytic_Apps" className="first-link">
              ANALYTIC
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">APPLICANT</span>
          </div>
        );
      } else {
        return (
          <div>
            <Link
              className="first-link"
              to={{
                pathname: "/Analytic_Apps",
                PageCurrent: pnAnalytic_PageCurrent,
                UISearch: aMemPageAll.UISearch
              }}
            >
              ANALYTIC
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <Link
              className="first-link"
              to={{
                pathname: "/Analytic_Apps",
                PageCurrent: pnAnalytic_PageCurrent,
                UISearch: aMemPageAll.UISearch
              }}
            >
              APPLICANT
            </Link>
            <span className="icon-right">
              <i className="fas fa-chevron-right" />
            </span>
            <span className="non-active-link">
              {aMemPageAll.AccStatusCodeType} = {aMemPageAll.TotalUnitHolder}{" "}
              record
            </span>
          </div>
        );
      }
    case "transaction":
    case "transaction_details":
      return (
        <div>
          <Link to="#" className="first-link">
            TRANSACTION
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to="#" className="first-link">
            TRANSACTION DETAILS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
        </div>
      );
    case "rsp_account_details":
      return (
        <div>
          <Link to="#" className="first-link">
            TRANSACTION
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to="#" className="first-link">
            RSP ACCOUNT DETAILS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
        </div>
      );
    case "request_ats_details":
      return (
        <div>
          <Link to="#" className="first-link">
            TRANSACTION
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <Link to="#" className="first-link">
            REQUEST ATS DETAILS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
        </div>
      );
    case "fund_list":
      return (
        <div>
          <Link to="#" className="first-link">
            FUND LIST
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">EDIT</span>
        </div>
      );
    case "fund_listDetail":
      return (
        <div>
          <Link
            className="first-link"
            to={{
              pathname: "/fund_list",
              PageCurrent: pnFundsList_PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
          >
            FUND LIST
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">{aMemPageAll.mode}</span>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">
            {
            aMemPageAll.mode === "CREATE" ? "": aMemPageAll?.detailsFromList?.fund_no
            }
          </span>
        </div>
      );
    default:
      // Page List มักจะอยู่หน้าแรก
      return (
        <Link to="#" className="first-link">
          {returnRouteName(tPath1)}
        </Link>
      );
  }
};

// start test
export const Breadcrumbs = ({ selectedObject }) => {
  let aMemPageAll;
  let nUsrAct_PageCurrent = 0;
  let nUntHld_PageCurrent = 0;

  const processSelectedObject = () => {
    if (selectedObject !== null && selectedObject.MemPageAll !== "") {
      const { type, payload } = selectedObject.MemPageAll;
      switch (type) {
        case "[3] MEM_UsrActDETAIL":
        case "MEM_FUNDRECRISKDETAIL_EDIT":
          aMemPageAll = payload;
          nUsrAct_PageCurrent = payload.PageCurrent;
          break;
        case "SET_CURRENT_CUSTOMER":
        case "SET_CURRENT_CUSTOMER_UNTHLD_DETAIL":
        case "MEM_TAGDETAIL_EDIT":
        case "MEM_FUNDRECCODIDETAIL_EDIT":
        case "SET_MODE_FUND_DETAILS":
        case "MEM_CONFDDETAIL_EDIT":
        case "MEM_REGISDEVICEDETAIL":
        case "MEM_ACTIDETAIL":
        case "MEM_RESGIS_LOG_DETAIL":
        case "MEM_CONSENTVS_LIST":
        case "MEM_CONSENTVS_DETAIL":
        case "REQUEST_ANALYTIC_LIST":
        case "RECEIVE_EASYINV_DETAIL":
        case "RECEIVE_UNTHLD_DETAIL":
        case "MEM_NDID_LOG_DETAIL":
          aMemPageAll = payload;
          break;
        case "RECEIVE_UNTHLD_SRCH":
          aMemPageAll = payload.aMemPageUntHldSrch;
          nUntHld_PageCurrent = aMemPageAll.PageCurrent;
          break;
        default:
          break;
      }
    }
  };

  const renderClientsBreadcrumbs = () => {
    if (aMemPageAll === undefined) {
      return (
        <div className="breadcrumbs-wrap">
          <Link className="first-link" to="/UsrAct">
            CLIENTS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">USER ACCOUNT</span>
        </div>
      );
    } else {
      return (
        <div className="breadcrumbs-wrap">
          <Link
            className="first-link"
            to={{
              pathname: "/UsrAct",
              PageCurrent: nUsrAct_PageCurrent,
              UISearch: aMemPageAll.UISearch
            }}
          >
            CLIENTS
          </Link>
          <span className="icon-right">
            <i className="fas fa-chevron-right" />
          </span>
          <span className="non-active-link">USER ACCOUNT</span>
        </div>
      );
    }
  };

  processSelectedObject();

  return (
    <Route
      path="*"
      render={(props) => {
        let parts = props.location.pathname.split("/");

        let isClients = parts.slice(1, parts.length)[0] === "";
        parts = parts.slice(1, parts.length - 1);

        if (isClients) {
          return renderClientsBreadcrumbs();
        }

        return (
          <div className="breadcrumbs-wrap">
            {GENxLink(
              props.location.pathname,
              aMemPageAll,
              nUsrAct_PageCurrent,
              nUntHld_PageCurrent
            )}
          </div>
        );
      }}
    />
  );
};
// end test