import React from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { numberToOrdinalString } from "../../../utils/functions/number_to_ordinals";
import { sourceOfFundCodeToNameMap } from "./country";
import th from "date-fns/locale/th";
import "./style.scss";
import { MEMUsrActDetail } from "../../UsrActDetail/redux/actions";
import { transformRawDate } from "../../../utils/functions/date_formatter";
registerLocale("th", th);

const toCompare = (a = {}, b = {}) => {
  // we use it for objects with the same structure
  const aValues = Object.values(a);
  const bValues = Object.values(b);
  return !aValues.some((aValue, index) => aValue !== bValues[index]);
};

const verificationChannelOptions = [
  { text: "Selfie with ID Card", value: "SELFIE", code: "SELFIE" },
  { text: "NDID", value: "NDID", code: "NDID" },
  { text: "Counter Service", value: "COUNTER_SERVICE", code: "COUNTER_SERVICE" }
];

const mailingAddressOptions = [
  {
    text: "Same as Address as specified in the identification document",
    value: "1",
    code: "1"
  },
  { text: "Current Address", value: "2", code: "2" },
  { text: "Company Address", value: "3", code: "3" },
  { text: "Other", value: "4", code: "4" }
];

const taxOptions = [
  { text: "Yes", value: true, code: "Y" },
  { text: "No", value: false, code: "N" }
];

const sameContactResidentialOptions = [
  { text: "Yes", value: true, code: "Y" },
  { text: "No", value: false, code: "N" }
];

const objectiveInvestmentCodeMap = [
  {
    code: "401",
    value: "401",
    label: "เพื่อการลงทุน",
    nameEn: "เพื่อการลงทุน",
    nameTh: "เพื่อการลงทุน"
  },
  {
    code: "402",
    value: "402",
    label: "เพื่อการเกษียณ",
    nameEn: "เพื่อการเกษียณ",
    nameTh: "เพื่อการเกษียณ"
  },
  {
    code: "403",
    value: "403",
    label: "เพื่อสิทธิประโยชน์ทางภาษี",
    nameEn: "เพื่อสิทธิประโยชน์ทางภาษี",
    nameTh: "เพื่อสิทธิประโยชน์ทางภาษี"
  },
  {
    code: "404",
    value: "404",
    label: "อื่นๆ",
    nameEn: "อื่นๆ",
    nameTh: "อื่นๆ"
  }
];

const riskLevelToNameMap = [
  {
    code: "1",
    value: "Low Risk (Level 1)",
    label: "Low Risk (Level 1)",
    nameEn: "Low Risk (Level 1)",
    nameTh: "ความเสี่ยงต่ำ (ระดับ 1)"
  },
  {
    code: "2",
    value: "Medium to Low Risk (Level 2)",
    label: "Medium to Low Risk (Level 2)",
    nameEn: "Medium to Low Risk (Level 2)",
    nameTh: "ความเสี่ยงปานกลางค่อนข้างต่ำ (ระดับ 2)"
  },
  {
    code: "3",
    value: "Medium to High Risk (Level 3)",
    label: "Medium to High Risk (Level 3)",
    nameEn: "Medium to High Risk (Level 3)",
    nameTh: "ความเสี่ยงปานกลางค่อนข้างสูง (ระดับ 3)"
  },
  {
    code: "4",
    value: "High Risk (Level 4)",
    label: "High Risk (Level 4)",
    nameEn: "High Risk (Level 4)",
    nameTh: "ความเสี่ยงสูง (ระดับ 4)"
  },
  {
    code: "5",
    value: "Aggressive (Level 5)",
    label: "Aggressive (Level 5)",
    nameEn: "Aggressive (Level 5)",
    nameTh: "ความเสี่ยงสูงมาก (ระดับ 5)"
  }
];

const SHWxMarkRFLabel = ptFName => {
  
  switch (ptFName) {
    case "detail.firstName":
    case "detail.lastName":
    case "detail.identificationNo":
    case "risk.level":
    case "residentialAddress.countryCode":
    case "occupation.occupationCode":
    case "occupation.incomeCode":
    case "occupation.sourceOfIncomeCode":
    case "occupation.sourceOfFundCode":
      return (
        <div style={{ color: "#ff0000", fontSize: 18, paddingLeft: 5 }}>*</div>
      );
    default:
  }
};

// start test
const SHWxMarkRFDrpDwn = (pbDisabled, ptFName, ptValShw) => {
  if ([
    "detail.firstName",
    "detail.lastName",
    "detail.identificationNo",
    "risk.level",
    "residentialAddress.countryCode",
    "occupation.occupationCode",
    "occupation.incomeCode",
    "occupation.sourceOfIncomeCode",
    "occupation.sourceOfFundCode"
  ].includes(ptFName)) {
    return ptValShw === null ? "dropDownRequire dropDown" : "dropDownDisabled dropDown";
  }

  return "dropDownDisabled dropDown";
};

// end test

// start test
const fieldMapping = {
  "detail.firstName": "detail.firstName",
  "detail.lastName": "detail.lastName",
  "detail.identificationNo": "detail.identificationNo",
  "risk.level": "risk.level",
  "residentialAddress.countryCode": "residentialAddress.countryCode",
  "occupation.occupationCode": "occupation.occupationCode",
  "occupation.incomeCode": "occupation.incomeCode",
  "occupation.sourceOfIncomeCode": "occupation.sourceOfIncomeCode",
  "occupation.sourceOfFundCode": "occupation.sourceOfFundCode"
};

const SHWxMarkRFTxtBox = (pbDisabled, ptFName, poClientDetail) => {
  let oVal = null;
  if (poClientDetail && fieldMapping[ptFName]) {
    try {
      // Assuming you can access nested properties like poClientDetail['detail']['firstName']
      oVal = poClientDetail[fieldMapping[ptFName]]; 
    } catch (err) {
      console.log('SHWxMarkRFTxtBox catch', err);
    }
  }

  //const fieldClass = oVal === null || oVal === "" ? "fieldValueRequire" : "fieldValueDisabled";
  const fieldClass = "fieldValueDisabled";
  return fieldClass
};
// end test


function renderTitle(title, asof = "") {
  const formattedAsof =
    asof !== null && asof !== "" && asof !== undefined
      ? transformRawDate(asof)
      : "";
  return (
    <div className={"sectionTitle"}>
      <label className={"sectionTitleText"}>
        {title.toUpperCase()} <small>(As of {formattedAsof})</small>
      </label>
    </div>
  );
}

function radioButtons(fieldLabel, fieldName, disabled, options, defaultValue) {
  let initialValue = null;
  if (defaultValue) {
    initialValue = options.find(obj => obj.code === defaultValue);
    initialValue = initialValue.value;
  }

  return (
    <div className={"fieldContainer"}>
      <label className={`field`}>
        <span className="fieldLabel">{fieldLabel}</span>
        <div className="radioButtonsWrap">
          <RadioGroup
            aria-label={fieldLabel}
            value={initialValue}
            disabled={disabled}
            className={"radioButtonItem"}
          >
            {options.map((option) => {
              return (
                <FormControlLabel
                  key={option.text}
                  value={option.value}
                  disabled={disabled}
                  control={<Radio />}
                  label={option.text}
                />
              );
            })}
          </RadioGroup>
        </div>
      </label>
    </div>
  );
}

function radioButtonsMultipleChoice(
  fieldLabel,
  fieldName,
  disabled,
  options,
  defaultValue
) {
  let initialValue = null;
  if (defaultValue) {
    initialValue = options.find(obj => obj.code === defaultValue);
    initialValue = initialValue.value;
  }

  return (
    <div className={"fieldContainer"}>
      <label className={`field`}>
        <span className="fieldLabel">{fieldLabel}</span>
        <div className="customRadioButtonsWrap">
          <RadioGroup
            aria-label={fieldLabel}
            value={initialValue}
            disabled={disabled}
            className={"radioButtonItem"}
          >
            {options.map((option) => {
              return (
                <FormControlLabel
                  key={option.text}
                  value={option.value}
                  disabled={disabled}
                  control={<Radio />}
                  label={option.text}
                />
              );
            })}
          </RadioGroup>
        </div>
      </label>
    </div>
  );
}

function radioButtonMailingAddressChoice(
  fieldLabel,
  fieldName,
  disabled,
  options,
  defaultValue
) {
  let initialValue = null;
  try {
    if (defaultValue) {
      initialValue = options.find(obj => obj.code === defaultValue);
      initialValue = initialValue.value;
    }
  } catch (ex) {
    console.log('catch radioButtonMailingAddressChoice', ex);
  }

  return (
    <div className={"customerFieldContainer"}>
      <label className={`field`}>
        <span className="fieldLabel">{fieldLabel}</span>
        <div className="mailingAddressButtonsWrap">
          <RadioGroup
            aria-label={fieldLabel}
            value={initialValue}
            disabled={disabled}
            className={"radioButtonItem"}
          >
            {options.map((option) => {
              return (
                <FormControlLabel
                  key={option.text}
                  value={option.value}
                  disabled={disabled}
                  control={<Radio />}
                  label={option.text}
                />
              );
            })}
          </RadioGroup>
        </div>
      </label>
    </div>
  );
}


function datePicker(
  fieldLabel,
  placeholder,
  fieldLabel2,
  disabled,
  register,
  control,
  locale,
  defaultDate
) {
  return (
    <div className={"fieldContainer"}>
      <label className={`field`}>
        <span className="fieldLabel">{fieldLabel}</span>
        <div className="calendarWrap">
         
          <DatePicker
            selected={defaultDate}
            disabled={disabled}
            placeholderText={placeholder}
            dateFormat="dd MMMM yyyy "
            locale={locale}
          />
        </div>
      </label>
    </div>
  );
}

function dropdownInput(
  fieldLabel,
  fieldName,
  placeholder,
  disabled,
  register,
  dropDownData,
  dataValuesObject
) {
  let valueToDisplay = null;

  if (dropDownData.length > 0 && dataValuesObject) {
    const fieldParts = fieldName.split('.');
    let value = dataValuesObject;
  
    for (const part of fieldParts) {
      if(value != null){
        if (part in value) {
          value = value[part];
        } else {
          value = null;
          break;
        }
      }
    }
  
    if (value !== null) {
      valueToDisplay = dropDownData.find(item => {
        return item.code === value;
      });
    }
  } 

  return (
    <div className={"fieldContainer"}>
      <label className={`field`}>
        <span className="fieldLabel">
          {fieldLabel}
          {SHWxMarkRFLabel(fieldName)}
        </span>

        <Select
          options={dropDownData}
          value={valueToDisplay}
          placeholder={placeholder}
          className={SHWxMarkRFDrpDwn(disabled, fieldName, valueToDisplay)}
          isDisabled={disabled}
        />
      </label>
    </div>
  );
}
function normalInput(
  fieldLabel,
  fieldName,
  placeholder,
  disabled,
  register,
  clientDetail,
  hidden = false
) {
  return (
    <div className={"fieldContainer"} key={fieldName} hidden={hidden}>
      <label className={`field`}>
        <span className="fieldLabel">
          {fieldLabel}
          {SHWxMarkRFLabel(fieldName)}
        </span>

        <input
          className={SHWxMarkRFTxtBox(disabled, fieldName, clientDetail)}
          disabled={disabled}
          id={fieldName}
          name={fieldName}
          placeholder={placeholder}
          ref={register}
        />
      </label>
    </div>
  );
}

function PersonalDetailsForm(props) {
  const {
    clientDetail,
    UsrAccountType,
    UserName,
    UsrActSrch,
    MEMUsrActDetail,
    dropDownData,
    locale
  } = props;
  
  let overallDefaultValuesObject = clientDetail ? clientDetail : {};
  let isBusinessTypeOtherVisible =
    clientDetail &&
    clientDetail.occupation &&
    clientDetail.occupation.businessType === "199"
      ? true
      : false;
  let isOccupationOtherVisible =
    clientDetail &&
    clientDetail.occupation &&
    clientDetail.occupation.occupationCode === "099"
      ? true
      : false;
  let isObjectiveInvestmentOtherVisible =
    clientDetail &&
    clientDetail.occupation &&
    clientDetail.occupation.objectiveInvestmentCode === "404"
      ? true
      : false;
  let isSourceOfIncomeOtherVisible =
    clientDetail &&
    clientDetail.occupation &&
    clientDetail.occupation.sourceOfIncomeCode === "207"
      ? true
      : false;
  let isSourceOfFundOther =
    clientDetail &&
    clientDetail.occupation &&
    clientDetail.occupation.isSourceOfFundOther === "60"
      ? true
      : false;
  let isDomesticMailingAddress =
    clientDetail &&
    clientDetail.mailingAddress &&
    clientDetail.mailingAddress.countryCode === "77"
      ? true
      : false;
  let isDomesticWorkingAddress =
    clientDetail &&
    clientDetail.workplaceAddress &&
    clientDetail.workplaceAddress.countryCode === "77"
      ? true
      : false;
  let isOldCustomer = clientDetail.isOldCustomer;
  let isForeigner =
    clientDetail &&
    clientDetail.detail &&
    clientDetail.detail.nationalityCode === "0"
      ? false
      : true;
  let isForeignContactAddress =
    clientDetail &&
    clientDetail.contactAddress &&
    clientDetail.contactAddress.countryCode !== "77"
      ? true
      : false;
  let isForeignReisdentialAddress =
    clientDetail &&
    clientDetail.residentialAddress &&
    clientDetail.residentialAddress.countryCode !== "77"
      ? true
      : false;
  let tMarryCode =
    clientDetail &&
    clientDetail.detail &&
    clientDetail.detail.maritalStatusCode;

  let listOfChildren =
    clientDetail && clientDetail.children && clientDetail.children.length > 0
      ? clientDetail.children
      : [];

  // Disconnect this object from props (JavaScript passes objects by reference, need to sever that reference )
  overallDefaultValuesObject = JSON.parse(
    JSON.stringify(overallDefaultValuesObject)
  );

  let defaultDate = new Date('1900-01-01');
  if (
    overallDefaultValuesObject &&
    overallDefaultValuesObject.detail &&
    overallDefaultValuesObject.detail.birthDate
  ) {
    if(overallDefaultValuesObject.detail.birthDate.trim().length > 1){
      defaultDate = new Date(overallDefaultValuesObject.detail.birthDate.trim());
    }
  }

  let expiryDate = new Date('2999-12-31');
  if (
    overallDefaultValuesObject &&
    overallDefaultValuesObject.detail &&
    overallDefaultValuesObject.detail.identificationExpireDate
  ) {
    if(overallDefaultValuesObject.detail.identificationExpireDate.trim().length > 1){
      expiryDate = new Date(overallDefaultValuesObject.detail.identificationExpireDate.trim());
    }
   
  }

  let defaultTaxOption = null;
  if (
    overallDefaultValuesObject &&
    overallDefaultValuesObject.detail &&
    overallDefaultValuesObject.detail.taxOption
  ) {
    defaultTaxOption = overallDefaultValuesObject.detail.taxOption;
  }

  let defaultVerificationChannelOption = null;
  if (overallDefaultValuesObject.verifyChannel) {
    defaultVerificationChannelOption = overallDefaultValuesObject.verifyChannel;
  }

  let defaultSameContactAndResidentialAddress = null;
  if (
    overallDefaultValuesObject &&
    overallDefaultValuesObject.contactAddress &&
    overallDefaultValuesObject.residentialAddress
  ) {
    const { contactAddress, residentialAddress } = overallDefaultValuesObject;
    defaultSameContactAndResidentialAddress = toCompare(
      contactAddress,
      residentialAddress
    );
    defaultSameContactAndResidentialAddress = defaultSameContactAndResidentialAddress
      ? "Y"
      : "N";
  }

  // * HARDCODE MAILING TYPE ADDRESS
  let defaultMailingAddressOption = null;
  if (clientDetail && clientDetail.mailingAddress) {
    defaultMailingAddressOption =
      clientDetail.mailingAddress.mailingAddressType;
  }

  let showOccupationTypeField = false;
  if (overallDefaultValuesObject && overallDefaultValuesObject.occupation) {
    const {
      occupation: { occupationCode }
    } = overallDefaultValuesObject;
    if (occupationCode === "060713") {
      console.log('occupationCode === "060713"');
    }
    if (occupationCode === "130101") {
      showOccupationTypeField = true;
    }
  }

  const { register, handleSubmit, control } = useForm({
    defaultValues: overallDefaultValuesObject
  });

  const onSubmit = () => {
  };

  const SETxMemUsrAct = oRows => {
    MEMUsrActDetail({ RowDataAt: oRows });
  };

  const personalDetails =
    clientDetail && clientDetail.detail && clientDetail.detail.createdAt
      ? true
      : false;
  const personalDetailsAsof = personalDetails
    ? clientDetail.detail.updatedAt !== undefined
      ? clientDetail.detail.updatedAt !== null
        ? clientDetail.detail.updatedAt
        : clientDetail.detail.createdAt
      : ""
    : "";

  const residentialAddress =
    clientDetail && clientDetail.residentialAddress ? true : false;
  const residentialAddressAsof = residentialAddress
    ? clientDetail.residentialAddress.updatedAt !== undefined
      ? clientDetail.residentialAddress.updatedAt !== null
        ? clientDetail.residentialAddress.updatedAt
        : clientDetail.residentialAddress.createdAt
      : ""
    : "";

  const customerBank = clientDetail && clientDetail.bank ? true : false;
  const customerBankAsof = customerBank
    ? clientDetail.bank.updatedAt !== undefined
      ? clientDetail.bank.updatedAt !== null
        ? clientDetail.bank.updatedAt
        : clientDetail.bank.createdAt
      : ""
    : "";

  const contactAddress =
    clientDetail && clientDetail.contactAddress ? true : false;
  const contactAddressAsof = contactAddress
    ? clientDetail.contactAddress.updatedAt !== undefined
      ? clientDetail.contactAddress.updatedAt !== null
        ? clientDetail.contactAddress.updatedAt
        : clientDetail.contactAddress.createdAt
      : ""
    : "";

  const occupation = clientDetail && clientDetail.occupation ? true : false;
  const occupationAsof = occupation
    ? clientDetail.occupation.updatedAt !== undefined
      ? clientDetail.occupation.updatedAt !== null
        ? clientDetail.occupation.updatedAt
        : clientDetail.occupation.createdAt
      : ""
    : "";

  const workplaceAddress =
    clientDetail && clientDetail.workplaceAddress ? true : false;
  const workplaceAddressAsof = workplaceAddress
    ? clientDetail.workplaceAddress.updatedAt !== undefined
      ? clientDetail.workplaceAddress.updatedAt !== null
        ? clientDetail.workplaceAddress.updatedAt
        : clientDetail.workplaceAddress.createdAt
      : ""
    : "";

  const mailingAddress =
    clientDetail && clientDetail.mailingAddress ? true : false;
  const mailingAddressAsof = mailingAddress
    ? clientDetail.mailingAddress.updatedAt !== undefined
      ? clientDetail.mailingAddress.updatedAt !== null
        ? clientDetail.mailingAddress.updatedAt
        : clientDetail.mailingAddress.createdAt
      : ""
    : "";

  // disable whole form
  let disabled = true;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={""}>
      {renderTitle("Personal Details", personalDetailsAsof)}
      {radioButtonsMultipleChoice(
        "Identify Verification By",
        "detail.verifyChannel",
        disabled,
        verificationChannelOptions,
        defaultVerificationChannelOption
      )}
      <div className={"fieldContainer"}>
        <label className={`field`}>
          <span className="fieldLabel">User Account</span>
          {
            <Link
              to={`/UsrActDetail/${
                UsrActSrch.length > 0 ? UsrActSrch[0].id : null
              }`}
              className="first-link"
              onClick={() =>
                SETxMemUsrAct(UsrActSrch.length > 0 ? UsrActSrch[0] : null)
              }
            >
              {UserName}
            </Link>
          }
          <span className="fieldLabel">({UsrAccountType} Account)</span>
        </label>
      </div>

      {isForeigner === false
        ? [
            normalInput(
              "First Name in Thai",
              "detail.firstNameTh",
              "First Name in Thai",
              disabled,
              register
            ),
            normalInput(
              "Last Name in Thai",
              "detail.lastNameTh",
              "Last Name in Thai",
              disabled,
              register
            )
          ]
        : null}
      {normalInput(
        "First Name",
        "detail.firstName",
        "First Name",
        disabled,
        register,
        clientDetail
      )}
      {normalInput(
        "Last Name",
        "detail.lastName",
        "Last Name",
        disabled,
        register,
        clientDetail
      )}
      {dropdownInput(
        "ID Type",
        "detail.identificationTypeCode",
        "ID Type",
        disabled,
        register,
        dropDownData.doctypes,
        clientDetail
      )}
      {normalInput(
        "ID No.",
        "detail.identificationNo",
        "ID No.",
        disabled,
        register,
        clientDetail
      )}
      {datePicker(
        "Expiry Date",
        "detail.identificationExpireDate",
        "Expiry Date",
        disabled,
        register,
        control,
        locale,
        expiryDate
      )}
      {datePicker(
        "Date of Birth",
        "detail.birthDate",
        "Date of Birth",
        disabled,
        register,
        control,
        locale,
        defaultDate
      )}
      {dropdownInput(
        "Nationality",
        "detail.nationalityCode",
        "Nationality",
        disabled,
        register,
        dropDownData.nationalities,
        clientDetail
      )}
      {normalInput(
        "Mobile No.",
        "detail.mobile",
        "Mobile No.",
        disabled,
        register
      )}
      {normalInput(
        "Email Address",
        "detail.email",
        "Email Address",
        disabled,
        register
      )}
      {dropdownInput(
        "Marital Status",
        "detail.maritalStatusCode",
        "Marital Status",
        disabled,
        register,
        dropDownData.maritalStatuses,
        clientDetail
      )}
      {dropdownInput(
        "Risk Level",
        "risk.level",
        "Risk Level",
        disabled,
        register,
        riskLevelToNameMap,
        clientDetail
      )}
      {radioButtons(
        "In case of dividend payment, I wish to have tax deduction",
        "detail.taxOption",
        disabled,
        taxOptions,
        defaultTaxOption
      )}

      <div className={"space"} />
      {renderTitle("Address on ID card", residentialAddressAsof)}
      {dropdownInput(
        "Country",
        "residentialAddress.countryCode",
        "Country",
        disabled,
        register,
        dropDownData.countries,
        clientDetail
      )}
      {normalInput(
        "Address Line 1",
        "residentialAddress.address1",
        "Address Line 1",
        disabled,
        register
      )}
      {normalInput(
        "Address Line 2",
        "residentialAddress.address2",
        "Address Line 2",
        disabled,
        register
      )}
      {isForeignReisdentialAddress
        ? normalInput(
            "District/Amphur",
            "residentialAddress.districtCode",
            "District/Amphur",
            disabled,
            register
          )
        : dropdownInput(
            "District/Amphur",
            "residentialAddress.districtCode",
            "District/Amphur",
            disabled,
            register,
            dropDownData.districtsResidential,
            clientDetail
          )}
      {normalInput(
        "Sub-district/Tambon",
        "residentialAddress.subDistrict",
        "Sub-district/Tambon",
        disabled,
        register
      )}
      {isForeignReisdentialAddress
        ? normalInput(
            "Province",
            "residentialAddress.provinceCode",
            "Province",
            disabled,
            register
          )
        : dropdownInput(
            "Province",
            "residentialAddress.provinceCode",
            "Province",
            disabled,
            register,
            dropDownData.provinces,
            clientDetail
          )}
      {normalInput(
        "Postal Code",
        "residentialAddress.postCode",
        "Postal Code",
        disabled,
        register
      )}
      {radioButtons(
        "Is your correspondence address the same as your residence registration address?",
        "detail.taxOption",
        disabled,
        sameContactResidentialOptions,
        defaultSameContactAndResidentialAddress
      )}

      {}
      <div className={"space"} />
      {tMarryCode === "M" ? renderTitle("Family Details") : null}
      {tMarryCode === "M"
        ? [
            normalInput(
              "Spouse First Name",
              "spouse.firstName",
              "Spouse First Name",
              disabled,
              register
            ),
            normalInput(
              "Spouse Last Name",
              "spouse.lastName",
              "Spouse Last Name",
              disabled,
              register
            )
          ]
        : null}

      {listOfChildren.length > 0
        ? listOfChildren.map((child, index) => [
            normalInput(
              `${numberToOrdinalString(index + 1)} Child’s First Name`,
              `children[${index}].firstName`,
              `${numberToOrdinalString(index + 1)} Child’s First Name`,
              disabled,
              register
            ),
            normalInput(
              `${numberToOrdinalString(index + 1)} Child’s Last Name`,
              `children[${index}].lastName`,
              `${numberToOrdinalString(index + 1)} Child’s Last Name`,
              disabled,
              register
            ),
            normalInput(
              `${numberToOrdinalString(index + 1)} Child’s ID No.`,
              `children[${index}].identificationNo`,
              `${numberToOrdinalString(index + 1)} Child’s ID No.`,
              disabled,
              register
            ),
            normalInput(
              `${numberToOrdinalString(index + 1)} Child’s Date of Birth`,
              `children[${index}].birthDate`,
              `${numberToOrdinalString(index + 1)} Child’s Date of Birth`,
              disabled,
              register
            )
          ])
        : null}

      {renderTitle("Bank Details", customerBankAsof)}
      {dropdownInput(
        "Bank Name",
        "bank.bankCode",
        "Bank Name",
        disabled,
        register,
        dropDownData.banks,
        clientDetail
      )}
      {normalInput(
        "Account Name",
        "bank.accountName",
        "Account Name",
        disabled,
        register
      )}
      {normalInput(
        "Account No.",
        "bank.accountNo",
        "Account No.",
        disabled,
        register
      )}

      {renderTitle("Current Address", contactAddressAsof)}
      {dropdownInput(
        "Country",
        "contactAddress.countryCode",
        "Country",
        disabled,
        register,
        dropDownData.countries,
        clientDetail
      )}
      {normalInput(
        "Address Line 1",
        "contactAddress.address1",
        "Address Line 1",
        disabled,
        register
      )}
      {normalInput(
        "Address Line 2",
        "contactAddress.address2",
        "Address Line 2",
        disabled,
        register
      )}
      {isForeignContactAddress
        ? normalInput(
            "District/Amphur",
            "contactAddress.districtCode",
            "District/Amphur",
            disabled,
            register
          )
        : dropdownInput(
            "District/Amphur",
            "contactAddress.districtCode",
            "District/Amphur",
            disabled,
            register,
            dropDownData.districtContactAddress,
            clientDetail
          )}
      {normalInput(
        "Sub-district/Tambon",
        "contactAddress.subDistrict",
        "Sub-district/Tambon",
        disabled,
        register
      )}
      {isForeignContactAddress
        ? normalInput(
            "Province",
            "contactAddress.provinceCode",
            "Province",
            disabled,
            register
          )
        : dropdownInput(
            "Province",
            "contactAddress.provinceCode",
            "Province",
            disabled,
            register,
            dropDownData.provinces,
            clientDetail
          )}
      {normalInput(
        "Postal Code",
        "contactAddress.postCode",
        "Postal Code",
        disabled,
        register
      )}

      {renderTitle("Occupation Details", occupationAsof)}

      {isOldCustomer === true
        ? normalInput(
            "Occupation",
            "occupation.occupationCode",
            "Occupation",
            disabled,
            register,
            clientDetail
          )
        : dropdownInput(
            "Occupation",
            "occupation.occupationCode",
            "Occupation",
            disabled,
            register,
            dropDownData.occupations,
            clientDetail
          )}
      {isOccupationOtherVisible === true
        ? normalInput(
            "Occupation Other",
            "occupation.occupationOther",
            "Occupation Other",
            disabled,
            register
          )
        : null}
      {dropdownInput(
        "Business Type",
        "occupation.businessType",
        "Business Type",
        disabled,
        register,
        dropDownData.businessTypes,
        clientDetail
      )}
      {isBusinessTypeOtherVisible === true
        ? normalInput(
            "Business Type Other",
            "occupation.businessOther",
            "Business Type Other",
            disabled,
            register
          )
        : null}
      {
      normalInput(
        "Position",
        "occupation.position",
        "Position",
        disabled,
        register
      )}
      {showOccupationTypeField
        ? normalInput(
            "Occupation Type",
            "occupation.occupationOther",
            "Occupation Type",
            disabled,
            register
          )
        : null}
      {isOldCustomer === true
        ? normalInput(
            "Monthly Income",
            "occupation.incomeCode",
            "Monthly Income",
            disabled,
            register,
            clientDetail
          )
        : dropdownInput(
            "Monthly Income",
            "occupation.incomeCode",
            "Monthly Income",
            disabled,
            register,
            dropDownData.monthlyIncomes,
            clientDetail
          )}
      {isOldCustomer === true
        ? normalInput(
            "Source of Income",
            "occupation.sourceOfIncomeCode",
            "Source of Income",
            disabled,
            register,
            clientDetail
          )
        : dropdownInput(
            "Source of Income",
            "occupation.sourceOfIncomeCode",
            "Source of Income",
            disabled,
            register,
            dropDownData.incomeSources,
            clientDetail
          )}
      {isSourceOfIncomeOtherVisible === true
        ? normalInput(
            "Source of Income Other",
            "occupation.sourceOfIncomeOther",
            "Source of Income Other",
            disabled,
            register,
            clientDetail
          )
        : null}
      {isSourceOfFundOther === true
        ? normalInput(
            "Source of Fund Other",
            "occupation.sourceOfFundOther",
            "Source of Fund Other",
            disabled,
            register
          )
        : dropdownInput(
            "Source of Fund",
            "occupation.sourceOfFundCode",
            "Source of Fund",
            disabled,
            register,
            sourceOfFundCodeToNameMap,
            clientDetail
          )}
      {normalInput(
        "Asset Value",
        "occupation.totalAssetValue",
        "Asset Value",
        disabled,
        register
      )}
      {dropdownInput(
        "Investment Objective",
        "occupation.objectiveInvestmentCode",
        "Investment Objective",
        disabled,
        register,
        objectiveInvestmentCodeMap,
        clientDetail
      )}
      {isObjectiveInvestmentOtherVisible === true
        ? normalInput(
            "Investment Objective Other",
            "occupation.objectiveInvestmentOther",
            "Investment Objective Other",
            disabled,
            register
          )
        : null}

      {renderTitle("Workplace Address", workplaceAddressAsof)}
      {normalInput(
        "Company Name",
        "workplaceAddress.name",
        "Company Name",
        disabled,
        register
      )}
      {dropdownInput(
        "Country",
        "workplaceAddress.countryCode",
        "Country",
        disabled,
        register,
        dropDownData.countries,
        clientDetail
      )}
      {normalInput(
        "Address Line 1",
        "workplaceAddress.address1",
        "Address Line 1",
        disabled,
        register
      )}
      {normalInput(
        "Address Line 2",
        "workplaceAddress.address2",
        "Address Line 2",
        disabled,
        register
      )}
      {isDomesticWorkingAddress === true
        ? dropdownInput(
            "District/Amphur",
            "workplaceAddress.districtCode",
            "District/Amphur",
            disabled,
            register,
            dropDownData.districtWork,
            clientDetail
          )
        : normalInput(
            "District/Amphur",
            "workplaceAddress.districtCode",
            "District/Amphur",
            disabled,
            register
          )}
      {normalInput(
        "Sub-district/Tambon",
        "workplaceAddress.subDistrict",
        "Sub-district/Tambon",
        disabled,
        register
      )}
      {isDomesticWorkingAddress
        ? dropdownInput(
            "Province",
            "workplaceAddress.provinceCode",
            "Province",
            disabled,
            register,
            dropDownData.provinces,
            clientDetail
          )
        : normalInput(
            "Province",
            "workplaceAddress.provinceCode",
            "Province",
            disabled,
            register
          )}
      {normalInput(
        "Postal Code",
        "workplaceAddress.postCode",
        "Postal Code",
        disabled,
        register
      )}

      {isOldCustomer === true ? null : (
        <div>
          {renderTitle("Mailing Address", mailingAddressAsof)}
          {radioButtonMailingAddressChoice(
            "",
            "mailingAddress.mailingAddressType",
            disabled,
            mailingAddressOptions,
            defaultMailingAddressOption
          )}

          {dropdownInput(
            "Country",
            "mailingAddress.countryCode",
            "Country",
            disabled,
            register,
            dropDownData.countries,
            clientDetail
          )}
          {normalInput(
            "Address Line 1",
            "mailingAddress.address1",
            "Address Line 1",
            disabled,
            register
          )}
          {normalInput(
            "Address Line 2",
            "mailingAddress.address2",
            "Address Line 2",
            disabled,
            register
          )}
          {isDomesticMailingAddress === true
            ? dropdownInput(
                "District/Amphur",
                "mailingAddress.districtCode",
                "District/Amphur",
                disabled,
                register,
                dropDownData.districtMailingAddress,
                clientDetail
              )
            : normalInput(
                "District/Amphur",
                "mailingAddress.districtCode",
                "District/Amphur",
                disabled,
                register
              )}

          {normalInput(
            "Sub-district/Tambon",
            "mailingAddress.subDistrict",
            "Sub-district/Tambon",
            disabled,
            register
          )}
          {isDomesticMailingAddress === true
            ? dropdownInput(
                "Province",
                "mailingAddress.provinceCode",
                "Province",
                disabled,
                register,
                dropDownData.provinces,
                clientDetail
              )
            : normalInput(
                "Province",
                "mailingAddress.provinceCode",
                "Province",
                disabled,
                register
              )}
          {normalInput(
            "Postal Code",
            "mailingAddress.postCode",
            "Postal Code",
            disabled,
            register
          )}
        </div>
      )}
    </form>
  );
}

const withConnect = connect(
  state => ({
    dropDownData: state.globalReducer.dropDownDataReducer,
    ...state.untHldDetailReducer
  }),
  {
    MEMUsrActDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(PersonalDetailsForm);
