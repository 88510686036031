import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { authFormInput } from "../../components/AuthFormInputh/index";
import * as Button from "../../components/Buttons";
import { AlertModal } from "../../components/Modal";
import { signIn } from "../../globalRedux/constants/loader";
import { aConfigUrl } from "../../utils/ConfigUrl";
import { validate } from "../../utils/functions/login_form_validation";
import { resetFailedRequestProps } from "./../../containers/Authentication/redux/actions";
import { signInAction } from "./redux/actions";
import "./style.scss";
//
import { OktaAuth } from "@okta/okta-auth-js";
class LogoutScreen extends Component {
  state = {
    auth: false,
    errorStateMessage: false,
    stSHWAlrOktaStatus: true,
    name: ""
  };
  static defaultProps = {
    errorString: "Timeout.",
    form: { signin: { syncErrors: {} } },
    stSHWAlrOktaStatus: true
  };

  static getDerivedStateFromProps(props, state) {
    const {
      auth: { errorMessage },
      form: { signin = {} }
    } = props;
  
    const isActive = signin && signin.active;
  
    // If there's an error message, the form is active, and the state has an error message
    if (errorMessage && isActive && state.errorStateMessage) {
      props.resetFailedRequestProps();
      return { errorStateMessage: false };
    }
  
    // If there's no error message, clear the error state
    if (!errorMessage) {
      return { errorStateMessage: false };
    }
  
    // If there's an error message, set the error state to true
    return { errorStateMessage: true };
  }
  

  onHandleSubmit = ({ email, password }) => {
    this.props.signIn({ email, password });
  };

  onHandleSubmitRedirect = () => {
    let authClient = new OktaAuth(aConfigUrl());
    if (authClient) {
      console.log("Redirecting to Okta login...");
      authClient.signInWithRedirect();
    } else {
      console.log("authClient instance not available.");
    }
  };

  static propTypes = {
    signIn: PropTypes.func
  };

  SHWAlrErr = (errorStateMessage, syncErrors, loading) => {
    if (errorStateMessage === false && loading === false) {
      return (
        <AlertModal
          show={this.state.stSHWAlrOktaStatus}
          onHide={() => {
            this.setState({ stSHWAlrOktaStatus: false });
          }}
          onHandleOK={() => {
            this.setState({ stSHWAlrOktaStatus: false });
          }}
          type={"Disapprove"}
          status={"1"}
          title={
            this.props.auth.OKTAStatus.errorCode +
            "|" +
            this.props.auth.OKTAStatus.name +
            "|" +
            this.props.auth.OKTAStatus.errorSummary
          }
        />
      );
    }
  };

  render() {
    const { errorStateMessage } = this.state;
    const {
      form: { signin },
      auth: { errorMessage },
      handleSubmit,
      loading
    } = this.props;
    let syncErrors = signin && signin.syncErrors;
    const classButton = errorMessage ? "bluer" : "";

    return (
      <div className="wrapper">
        <div className="imageBackgroundWrap" />
        <div className="formWrap">
          <div className="formContainer">
            <div className="formName">
              <h2>You have successfully logged out.</h2>
            </div>
          </div>
          <div className="container-reset-password">
            <p className="Cant-access-your-ac">
                You will be redirected to the login page shortly.
            </p>
          </div>
          <div className="copyright">
            &#169; 2024 All Rights Reserved. {aConfigUrl().TextPhase}{" "}
            {aConfigUrl().Version}
          </div>
        </div>
      </div>
    );
  }
}

const ReduxForm = reduxForm({
  form: "signin",
  validate
});

const withConnect = connect(
  state => ({
    auth: state.auth,
    clients: state.clientReducer,
    globalProps: state.globalReducer,
    form: state.form,
    loading: state.loaderReducer[signIn]
  }),
  { signIn: signInAction, resetFailedRequestProps }
);

export default compose(
  ReduxForm,
  withConnect,
  withRouter
)(LogoutScreen);
